import { ProductRelease, ReleaseStatus, ReleaseStatusValue } from '@adsk/offsite-dc-sdk';
import Alert from '@mui/material/Alert';
import React from 'react';
import text from '../../../global/text.json';
import { releaseUpdateIds } from '../../../tests/helpers/dataTestIds';

interface ReleaseUpdateWarningMessageProps {
  newReleaseStatus: ReleaseStatusValue;
  currentRelease: ProductRelease;
  releaseCount: number;
  obsoleteReleasesCount: number;
  isLastActiveRelease: boolean;
}

const releasesText = text.releases;
const ReleaseUpdateWarningMessage: React.FC<ReleaseUpdateWarningMessageProps> = ({
  newReleaseStatus,
  currentRelease,
  releaseCount,
  obsoleteReleasesCount,
  isLastActiveRelease,
}) => {
  // TODO: Remove when API implementation is complete to allow all releases to be obsolete
  // Alert the user that they cannot update the release status to obsolete
  if (isLastActiveRelease) {
    return (
      <Alert severity="error" data-testid={releaseUpdateIds.releaseUpdateWarning}>
        {releasesText.lastActiveReleaseWarning}
      </Alert>
    );
  }

  // If there is only two releases, the other release will become default
  if (currentRelease.status === ReleaseStatus.DEFAULT && releaseCount === 2) {
    return (
      <Alert severity="error" data-testid={releaseUpdateIds.releaseUpdateWarning}>
        {releasesText.defaultToActiveWarning}
      </Alert>
    );
  }
  // If switching release to default, it converts the default release to active
  if (
    (currentRelease.status === ReleaseStatus.ACTIVE || currentRelease.status === ReleaseStatus.OBSOLETE) &&
    newReleaseStatus === ReleaseStatus.DEFAULT &&
    releaseCount - obsoleteReleasesCount > 1
  ) {
    return (
      <Alert severity="error" data-testid={releaseUpdateIds.releaseUpdateWarning}>
        {releasesText.activeToDefaultWarning}
      </Alert>
    );
  }

  return null;
};
export default ReleaseUpdateWarningMessage;
