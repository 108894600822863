import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import React from 'react';
import buildConstructionGreySvg from '../../assets/svg/building_construction_grey.svg';
import desktopComputerGreySvg from '../../assets/svg/desktop_computer_grey.svg';
import emptyBoxSvg from '../../assets/svg/empty_box_illustration.svg';
import filingCabinetGreySvg from '../../assets/svg/filing_cabinet_grey.svg';
import folderEmptyGrey from '../../assets/svg/folder_empty_grey.svg';
import pagesPhotoSvg from '../../assets/svg/pages_photo.svg';
import pagesTextGreySvg from '../../assets/svg/pages_text_grey.svg';
import text from '../../common.text.json';
import { emptyStateTestIds } from '../../global/dataTestIds';
import { EmptyStateContainer, EmptyStateImage } from '../../styles/Common.styles';

const illustrations = {
  BUILDING_CONSTRUCTION_GREY: buildConstructionGreySvg,
  SYSTEM_ERROR: desktopComputerGreySvg,
  PAGES_PHOTO: pagesPhotoSvg,
  NO_RESULTS: pagesTextGreySvg,
  FILING_CABINET_GREY: filingCabinetGreySvg,
  FOLDER_EMPTY_GREY: folderEmptyGrey,
  EMPTY_BOX: emptyBoxSvg,
};

type IllustrationType = keyof typeof illustrations;

type Illustrations = {
  [key in IllustrationType]: key;
};

export const EMPTY_STATE_ILLUSTRATION_TYPES: Illustrations = {
  BUILDING_CONSTRUCTION_GREY: 'BUILDING_CONSTRUCTION_GREY',
  SYSTEM_ERROR: 'SYSTEM_ERROR',
  PAGES_PHOTO: 'PAGES_PHOTO',
  NO_RESULTS: 'NO_RESULTS',
  FILING_CABINET_GREY: 'FILING_CABINET_GREY',
  FOLDER_EMPTY_GREY: 'FOLDER_EMPTY_GREY',
  EMPTY_BOX: 'EMPTY_BOX',
};

export interface MIDEmptyStateProps {
  title?: string;
  hideTitle?: boolean;
  description?: string | JSX.Element;
  illustrationType?: IllustrationType;
  hideIllustration?: boolean;
  warning?: string;
}

export const MIDEmptyState: React.FC<MIDEmptyStateProps> = ({
  title = text.EmptyStateNoData,
  hideTitle,
  illustrationType = EMPTY_STATE_ILLUSTRATION_TYPES.FILING_CABINET_GREY,
  description,
  hideIllustration,
  warning,
}): JSX.Element => (
  <EmptyStateContainer data-testid={emptyStateTestIds.midEmptyStateContainer}>
    {!hideIllustration && (
      <EmptyStateImage
        src={illustrations[illustrationType]}
        alt={text.emptyStateAltText}
        data-id={emptyStateTestIds.midEmptyStateSvg}
      />
    )}
    {!hideTitle && (
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>
    )}
    {description && (
      <Typography variant="caption" component="p">
        {description}
      </Typography>
    )}
    {warning && <Alert severity="warning">{warning}</Alert>}
  </EmptyStateContainer>
);

export default MIDEmptyState;
