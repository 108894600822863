import React, { ReactNode } from 'react';
import MIDNotification from '../../components/Notification/Notification';
import { NotificationStore } from './notificationStore';

const NotificationContext = React.createContext({} as NotificationStore);

interface NotificationsProviderProps {
  children: ReactNode;
  store: NotificationStore;
}

export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({ children, store }) => (
  <NotificationContext.Provider value={store}>
    {children}
    {store.items.map((item, index) => (
      <MIDNotification
        key={index}
        seqNumber={index}
        message={item.message}
        messageBody={item.messageBody}
        severity={item.severity}
        anchor={item.anchor}
        handleClose={item.handleClose}
        id={item.id}
      />
    ))}
  </NotificationContext.Provider>
);

export default NotificationContext;
