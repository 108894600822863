import { Variant, VariantTextInput, VariantNumericInput, InputType } from '@adsk/offsite-dc-sdk';

export const migrateVariantMultiValueInputs = (variant: Variant): Variant => {
  const compatibleVariant: Variant = {
    ...variant,
    inputs: variant.inputs.map((input) => {
      if (input.type === InputType.MULTI_VALUE_TEXT) {
        const textInput: VariantTextInput = {
          type: InputType.TEXT,
          name: input.name,
          label: input.label,
          value: input.value,
          unit: input.unit,
          visible: input.visible,
        };
        return textInput;
      }

      if (input.type === InputType.MULTI_VALUE_NUMERIC) {
        const numericInput: VariantNumericInput = {
          type: InputType.NUMERIC,
          name: input.name,
          label: input.label,
          value: input.value,
          unit: input.unit,
          visible: input.visible,
        };
        return numericInput;
      }

      return input;
    }),
  };
  return compatibleVariant;
};
