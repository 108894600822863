import { styled, css } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';

export const NotificationTitle = styled('h4')`
  margin: 0 0 ${({ theme }) => `${theme.var.marginBase}px`} 0;
`;

type StyledSnackbarProps = {
  topOffset?: number;
};

// have to add !important to override the default top value set by MUI
export const StyledSnackbar = styled(Snackbar, { shouldForwardProp: (prop) => prop !== 'topOffset' })<StyledSnackbarProps>`
  ${({ topOffset }) => topOffset !== undefined && css(`top: ${topOffset}px !important;`)}
`;
