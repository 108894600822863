import { AuthenticationError, UserInfoError, getReactAppEnvironment } from 'mid-utils';
import { authPkceSdkConfig } from './authConfig';
import text from '../global/text.json';
import { UserInfo } from '../types/user';
import { AuthClient, GenericError } from '@adsk/auth-pkce-sdk';
import { AUTH_STORAGE_KEYS } from 'global/constants/auth';
import { ServiceConfigMap, ServiceTypes } from 'mid-utils';

const authText = text.auth;

export class AuthService {
  public token?: string;
  public authClient!: AuthClient;

  // Initialize Auth Service must be called before any other method
  public async initialize(): Promise<void> {
    // Instantiating the Auth PKCE SDK AuthClient
    this.authClient = await new AuthClient(authPkceSdkConfig);
  }

  public async authenticate(): Promise<string | undefined> {
    try {
      const sessionCached = Object.keys(window.sessionStorage).filter((key) =>
        key.startsWith(AUTH_STORAGE_KEYS.AUTH_SPA_SDK_PERSISTED_PREFIX),
      );

      if (
        (window.location.href.includes(AUTH_STORAGE_KEYS.LOGIN_REDIRECT_URL_CODE) &&
          window.localStorage.getItem(AUTH_STORAGE_KEYS.PKCE_CODE_VERIFIER)) ||
        window.sessionStorage.getItem(sessionCached[0])
      ) {
        const token = await this.authClient.getAccessTokenSilently({ ignoreCache: false });
        this.token = token;

        return token;
      }

      await this.authClient.loginWithRedirect();
    } catch (e: unknown) {
      // Error login_required could happen when user logged out from Autodesk account and:
      // - comes back to webapp page, with some cached token in session storage
      // - comes back to webapp page with some login code in the url
      if (e instanceof GenericError && e.error === AUTH_STORAGE_KEYS.LOGIN_REQUIRED_ERROR) {
        await this.logout();
        return;
      }
      throw new AuthenticationError(authText.invalidToken);
    }
  }

  public async getOAuth2Token(): Promise<string> {
    return await this.authClient.getAccessTokenSilently({ ignoreCache: false });
  }

  public async getUserInfo(): Promise<UserInfo> {
    try {
      if (!this.token) {
        throw new UserInfoError(authText.failToGetUserInfo, { error: authText.invalidToken });
      }
      const userInfo: UserInfo = await this.authClient.getUserProfile(this.token);

      return { ...userInfo, email: userInfo.emailId };
    } catch (e: unknown) {
      throw new UserInfoError(authText.failToGetUserInfo, { error: e });
    }
  }

  public async logout(): Promise<void> {
    const currentEnv = getReactAppEnvironment();
    return await this.authClient.logout({
      post_logout_redirect_uri: ServiceConfigMap[ServiceTypes.MID_WEBAPP][currentEnv].api,
    });
  }
}

export default new AuthService();
