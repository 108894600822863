export type AmplitudeIdentifyProperties = {
  userOxygenID?: string;
  userEmail?: string;
};

interface IAmplitude {
  identify(this: IAmplitude, id: string, properties: AmplitudeIdentifyProperties): void;
  load(config: { client: { apiKey: string; configuration: { defaultTracking: boolean } } }): void;
  setGroup(this: IAmplitude, groupType: string, groupValue: string): void;
}

export interface IHandleAmplitudeIdentify {
  (id: string, properties: AmplitudeIdentifyProperties): void;
}
export interface ISetAmplitudeGroup {
  (groupType: string, groupValue: string): void;
}
export function handleAmplitudeIdentify(this: IAmplitude, id: string, properties: AmplitudeIdentifyProperties): void {
  this.identify(id, {
    ...properties,
  });
}

export function initializeAmplitude(this: IAmplitude): void {
  this.load({ client: { apiKey: import.meta.env.VITE_AMPLITUDE_KEY || '', configuration: { defaultTracking: true } } });
}

export function setAmplitudeGroup(this: IAmplitude, groupType: string, groupValue: string): void {
  this.setGroup(groupType, groupValue);
}

export const getDomainFromEmail = (email: string): string => {
  const match = email.match(/(?<=@)[^.]+(?=\.)|(?<=@)\w+\.\w+/);
  return match ? match[0] : '';
};
